import React from 'react';

const colorBoxes = {
    //Paars blok linksboven
    '0': '#470A68',
    '1': '#470A68',
    '10': '#470A68',
    '20': '#470A68',
    '30': '#470A68',
    '31': '#470A68',
    '40': '#470A68',
    '50': '#470A68',
    '51': '#470A68',

    //Blauw Blok links midden
    '2': '#005EB8',
    '3': '#005EB8',
    '4': '#005EB8',
    '11': '#005EB8',
    '12': '#005EB8',
    '13': '#005EB8',
    '21': '#005EB8',
    '22': '#005EB8',
    '32': '#005EB8',

    //Groen blok midden
    '41': '#00a3a1',
    '42': '#00a3a1',
    '43': '#00a3a1',
    '52': '#00a3a1',
    '53': '#00a3a1',
    '54': '#00a3a1',
    '62': '#00a3a1',
    '63': '#00a3a1',
    '72': '#00a3a1',

    //
    '48': '#005EB8',
    '57': '#005EB8',
    '58': '#005EB8',
    '64': '#005EB8',
    '65': '#005EB8',
    '66': '#005EB8',
    '67': '#005EB8',
    '68': '#005EB8',
    '75': '#005EB8',

    //Paars blok links midden
    '5': '#470A68',
    '6': '#470A68',
    '14': '#470A68',
    '23': '#470A68',
    '24': '#470A68',
    '25': '#470A68',
    '15': '#470A68',
    '16': '#470A68',
    '17': '#470A68',

    //Groen blok linksonder
    '8': '#00a3a1',
    '7': '#00a3a1',
    '18': '#00a3a1',
    '26': '#00a3a1',
    '27': '#00a3a1',
    '28': '#00a3a1',
    '37': '#00a3a1',
    '38': '#00a3a1',
    '47': '#00a3a1',

    '33': '#6D2077',
    '34': '#6D2077',
    '35': '#6D2077',
    '36': '#6D2077',
    '44': '#6D2077',
    '45': '#6D2077',
    '46': '#6D2077',
    '55': '#6D2077',
    '56': '#6D2077',


    //Paars blok rechts onder
    '74': '#470A68',
    '76': '#470A68',
    '77': '#470A68',
    '84': '#470A68',
    '85': '#470A68',
    '86': '#470A68',
    '87': '#470A68',
    '88': '#470A68',

    '60': '#005EB8',
    '61': '#005EB8',
    '73': '#005EB8',
    '71': '#005EB8',
    '70': '#005EB8',
    '83': '#005EB8',
    '82': '#005EB8',
    '81': '#005EB8',
    '80': '#005EB8',


    // '0': '#4e2379',
    // '11': '#4e2379',
    // '22': '#4e2379',
    // '33': '#4e2379',
    // '44': '#4e2379',
    // '55': '#4e2379',
    // '66': '#4e2379',
    // '77': '#4e2379',
    // '88': '#4e2379',
    // '80': '#4e2379',
    // '71': '#4e2379',
    // '62': '#4e2379',
    // '53': '#4e2379',
    // '35': '#4e2379',
    // '26': '#4e2379',
    // '17': '#4e2379',
    // '8': '#4e2379',
    //
    // '12': '#00a3a1',
    // '13': '#00a3a1',
    // '21': '#00a3a1',
    // '23': '#00a3a1',
    // '31': '#00a3a1',
    // '32': '#00a3a1',
    //
    // '15': '#00a3a1',
    // '16': '#00a3a1',
    // '25': '#00a3a1',
    // '27': '#00a3a1',
    // '36': '#00a3a1',
    // '37': '#00a3a1',
    //
    // '52': '#00a3a1',
    // '61': '#00a3a1',
    // '63': '#00a3a1',
    // '72': '#00a3a1',
    // '73': '#00a3a1',
    //
    // '56': '#00a3a1',
    // '57': '#00a3a1',
    // '67': '#00a3a1',
    // '65': '#00a3a1',
    // '75': '#00a3a1',
    // '76': '#00a3a1',
    //
    // '1': '#0091da',
    // '2': '#0091da',
    // '3': '#0091da',
    // '4': '#0091da',
    // '5': '#0091da',
    // '6': '#0091da',
    // '7': '#0091da',
    // '10': '#0091da',
    // '14': '#0091da',
    // '18': '#0091da',
    // '20': '#0091da',
    // '24': '#0091da',
    // '28': '#0091da',
    // '30': '#0091da',
    // '34': '#0091da',
    // '38': '#0091da',
    // '40': '#0091da',
    // '41': '#0091da',
    // '42': '#0091da',
    // '43': '#0091da',
    // '45': '#0091da',
    // '46': '#0091da',
    // '47': '#0091da',
    // '48': '#0091da',
    //
    // '50': '#0091da',
    // '54': '#0091da',
    // '58': '#0091da',
    // '60': '#0091da',
    // '64': '#0091da',
    // '68': '#0091da',
    // '70': '#0091da',
    // '74': '#0091da',
    // '78': '#0091da',
    // '81': '#0091da',
    // '82': '#0091da',
    // '83': '#0091da',
    // '84': '#0091da',
    // '85': '#0091da',
    // '86': '#0091da',
    // '87': '#0091da',




};

const getBoxColor = (row, col) => {
    let rowGroup = row - (row % 1);
    let colGroup = (col - (col % 1)) * 10;
    return colorBoxes[rowGroup + colGroup];
};

class Box extends React.Component {
    constructor(){
        super();
        this.state = {value: ''};
        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({value: e.target.value})
        }
    }

    render() {
        const {row, col, val} = this.props;
        let input;
        switch (val) {
            case "*":
                input = (
                    <div className="blok" style={{backgroundColor: '#005EB8'}}>
                        <input
                            className="input_fields"
                            ref='input'
                            value={this.state.value}
                            onChange={this.onChange}
                            maxLength="1"
                            style={{
                                borderRadius: '50px',
                                border: '2px solid #FFF',
                                backgroundColor: 'transparent'
                            }}
                        />
                    </div>
                );
                break;
            case "**":
                input = (
                    <div className="blok" style={{backgroundColor: '#4e2379'}}>
                        <input
                            className="input_fields"
                            ref='input'
                            maxLength="1"
                            value={this.state.value}
                            onChange={this.onChange}
                            style={{
                                borderRadius: '50px',
                                border: '2px solid #FFF',
                                backgroundColor: 'transparent'
                            }}
                        />
                    </div>
                );
                break;
            case "***":
                input = (
                    <div className="blok" style={{backgroundColor: '#00a3a1'}}>
                        <input
                            className="input_fields"
                            ref='input'
                            maxLength="1"
                            value={this.state.value}
                            onChange={this.onChange}
                            style={{
                                borderRadius: '50px',
                                border: '2px solid #FFF',
                                backgroundColor: 'transparent'
                            }}
                        />
                    </div>
                );
                break;
            default:
                input = (
                    <input
                        className="input_fields"
                        ref='input'
                        style={{backgroundColor: getBoxColor(row, col)}}
                        value={val ? val : this.state.value}
                        maxLength="1"
                        onChange={this.onChange}
                    />
                );
        }
        return (
            <td>
                {
                    input
                }
            </td>
        );
    }
}

export default Box;
