import React, {useRef, useContext, useEffect, useState} from 'react';

/* STYLING */

import './scss/main.scss';

/* COMPONENTS */

import ReceiveInput from './components/ReceiveInput';

/* IMAGES */

import logo from './static/images/logo.png';
import playbtn from './static/images/play.png';
import audio from './static/images/audio.png';
import balloon from './static/images/airpods_balloon_v2.png';
import nrclogo from './static/images/Layer 1.png';

/* VIDEO */

import succesVideo from './static/videos/kpmg_kluis_groot_crop_1_no_audio.mp4';
import {GameContext} from './context/GameContext';

/* EXTERNAL LIBS */

import {Container} from '@material-ui/core';
import Game from "./components/Game";

const App = ({store}) => {

    const {completed, wrong} = useContext(GameContext);
    const [popUp, setPopup] = useState(completed);
    const videoRef = useRef();

    useEffect(() => {

        const timePopup = () => {
            setTimeout(() => {
                setPopup(true);
            }, 2000)
        }

        if (completed) {
            videoRef.current.play();
            timePopup();
        }

    }, [completed])

    return (
        <div className='kpmg-onctijferd'>
            <div className='content'>
                <div className='hero'>
                    <div class="hero__vertical-bars"></div>
                    <Container maxWidth='lg'>
                        <div class='hero__wrap'>
                            <div className='hero__introduction'>
                                <div className='hero__header'>
                                    <div className='hero__header__logo'>
                                        <img src={logo} alt='KPMG LOGO'/>
                                    </div>
                                    <div className='hero__header__logo'>
                                        <img src={nrclogo} alt='NRC'/>
                                    </div>


                                </div>
                                <div className='hero__introduction__content'>
                                    <div className='hero__introduction__content__textblock'>
                                        <div className='title'>
                                            Vooruitgang <br/> ontcijferd
                                        </div>
                                        <div className='paragraph'>
                                            Get inspired by the KPMG 'Vooruitgang ontcijferd' campaign. Listen to the
                                            podcast series with Stephanie Hottenhuis discussing the transition to a new
                                            economy
                                            and the podcast with Frank van Praat about the right balance between man and
                                            machine.
                                        </div>
                                    </div>
                                    <div className='hero__introduction__content__podcast podcast'>
                                        <a target="blank" className='podcast__link'
                                           href='https://www.nrc.nl/brandedcontent/kpmg/'></a>
                                        <div class='podcast__portrait'>
                                            <div className='podcast__portrait__image'>

                                            </div>
                                            <div className='podcast__portrait__play-btn'>
                                                <img src={playbtn} alt='play button'/>
                                            </div>
                                        </div>
                                        <div class='podcast__content'>
                                            <div className='podcast__content__image'>
                                                <img src={audio} alt='audio button'/>
                                            </div>
                                            <div class='podcast__content__text'>
                                                <div className='paragraph light'>
                                                    <span className='bold-text'>Go to the NRC and KPMG branded content platform</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='hero__balloon'>
                                <div className='hero__balloon__image'>
                                    <img src={balloon} alt='price image'/>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className='gamefield'>
                    <Container maxWidth='lg'>
                        <div className='gamefield__header'>
                            <div className='title center'>
                                Decipher the safe code
                            </div>
                        </div>
                        <div className='gamefield__content'>
                            <div className='title title-sub'>
                                Jigsaw Sudoku
                            </div>

                            <div className='gamefield__content__game game'>
                                {popUp ? <div className='popup-wrap'>
                                    <div className='popup succes'>
                                        <div className='title'>You've figured out the code!</div>
                                        <p className='explain'>Send an e-mail to let us know and maybe you are the
                                            winner of this week’s AirPods</p>
                                        <a className='send-btn'
                                           href='mailto:NL-FMvooruitgang@kpmg.nl?subject=Vooruitgang ontcijferd code&body=hi colleagues,%0D%0A%0D%0AI’ve just figured out the code and I would like to participate to have a chance to win AirPods!%0D%0A%0D%0AMy name:%0D%0AMy KPMG e-mail address:%0D%0A%0D%0AThanks!%0D%0A%0D%0AAre you excited about the new Vooruitgang Ontcijferd campaign? Share the content via https://www.nrc.nl/brandedcontent/kpmg/'>Send
                                            e-mail</a>
                                    </div>
                                </div> : ''}
                                <div className='game__sudoku'>
                                    <Game store={store}/>
                                </div>
                                <div className='game__vault'>
                                    {wrong ? <div className='wrong_code'>
                                        <h1 className="title">So sorry, not<br/> the right code!</h1>
                                    </div> : ''}

                                    <div class='game__vault__video'>
                                        <video ref={videoRef} muted='muted' playsInline preload="metadata">
                                            <source src={`${succesVideo}#t=0.1`} type='video/mp4'/>
                                        </video>
                                    </div>

                                    {completed ? '' : <div className='game__vault__input'>
                                        <ReceiveInput/>
                                    </div>}

                                </div>
                            </div>

                        </div>

                    </Container>
                    <div className="questions"><p>For other questions or comments, please contact <a href='mailto:vooruitgang@kpmg.nl?subject=Vooruitgang ontcijferd'>NL-FMvooruitgang@kpmg.nl</a></p></div>
                </div>
            </div>
        </div>
    )

}

export default App;