import React, { useEffect, useState, createContext } from 'react';
import _ from 'lodash';
import confetti from 'canvas-confetti';
import {delay} from "lodash/function";

export const GameContext = createContext();

export const GameProvider = ({ children }) => {

    const initialAnswers = {
        answerOne: '',
        answerTwo: '',
        answerThree: '',
    }

    const [ clientGuess, setClientGuess ] = useState(initialAnswers);
    const [ answer, setAnswer ] = useState();
    const [ completed, setCompleted ] = useState(false);
    const [ wrong, setWrong ] = useState(false);

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        setClientGuess({
            ...clientGuess,
            [ name ] : value,
        })
    }

    const providerProps = {
        answer,
        completed,
        handleInputChange,
        wrong
    }

    useEffect(() => {

        /* Over het answer heen loopen en tot 3 x een random nummer tussen 0 en 9 toevoegen. */
        /* const answerKeys =  Object.keys(initialAnswers);
         const answerList = {...initialAnswers};
         answerKeys.map(key => {
             return answerList[key] = Math.ceil(Math.random() *  9).toString();
         });

         setAnswer(answerList);*/

        setAnswer({
            answerOne: '5',
            answerTwo: '8',
            answerThree: '1',
        })

    },[])

    useEffect(() => {

        const isEmpty = Object.values(clientGuess).some(x => x == null || x === '');

        const isEqual = _.isEqual(clientGuess,answer);

        setWrong(false);

        if(isEqual){
            setTimeout(function() { setCompleted(true)}.bind(this), 800);
            confetti({
                particleCount: 500,
                spread: 500
            });
        } else if(!isEmpty && !isEqual){
            setWrong(true);

        }
    },[clientGuess])


    return(
        <GameContext.Provider value={ providerProps } >
            { children }
        </GameContext.Provider>
    )
}

