import React from 'react';
import Grid from '../components/Grid';

class Game extends React.Component {

    render() {
        const {store} = this.props;
        const {grid, status} = store.getState();
        return (
            <div className="grid"><Grid grid={grid} status={status} {...this.props}/>
                <div className='paragraph oblique'>
                    Place the numbers 1-9 into the empty cells in such a way that in every colored shape and row each number appears only once. When you’ve finished the sudoku, type the three numbers with a circle around them into the safe to open it.
                    <br/>
                    <br/>
                        How does it work? These are the rules of the game:<br/>

                    <div className="block">
                        <ul>
                            <li>During four weeks, every Monday a new game will be presented on this page.</li>
                            <li>Every week, a winner will be randomly selected out of all the participants who figured
                                out the right code. You can only win AirPods once.
                            </li>
                            <li>Didn’t win the first time? Keep an eye on this website and try again next time!</li>
                            <li>If you're one of the lucky ones, you will automatically hear from us. (So no, mailing us
                                doesn't help ;-))
                            </li>
                            <li>Are you a BRM colleague? You can play too, but unfortunately you can’t be selected as a
                                winner.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Game;