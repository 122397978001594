import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GameProvider } from './context/GameContext';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const finalCreateStore = compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
)(createStore);

const store = finalCreateStore(rootReducer);

const Main = () => {
    return (
        <GameProvider>
            <App store={store}/>
        </GameProvider>
    )
}

ReactDOM.render(<Main/>, document.querySelector('#root'));