import React from 'react';
import Box from './Box';

class Grid extends React.Component {
    render() {
        const {grid} = this.props;
        const renderBox = (row, val, col) => {
            return (
                <Box
                    key={col}
                    row={row}
                    col={col}
                    val={val}
                    {...this.props}
                />
            );
        };
        const renderRow = (vals, row) => {
            return (
                <tr key={row}>
                    {vals.map(renderBox.bind(this, row))}
                </tr>
            );
        };

        return (
            <table>
                <tbody>
                {grid.map(renderRow)}
                </tbody>
            </table>

        );
    }
}

export default Grid;
