import cloneDeep from 'lodash/cloneDeep';

const initialState = [
	[0, 3, 0, 0, 0, 0, 0, 7, 0],
	[0, 8, 0, 0, 4, 0, 2, 0, 0],
	[0, 2, 5, 0, 0, 0, 0, 0, 4],
	["*", 0, 0, 0, 0, 0, "***", 0, 0],
	[0, 6, 8, 0, 0, 0, 0, 0, 0],
	[0, 0, 0, 6, 0, 1, 8, 0, 0],
	[0, 0, 0, 0, 0, 0, 0, 0, 0],
	[6, 7, 1, 2, 0, 5, 0, 9, 0],
	[3, 0, 0, 9, 0, 0, 0, "**", 0]
];

export function grid(state = cloneDeep(initialState), action) {
	switch (action.type) {
		case 'input':
		default:
			return state;
	}
}
