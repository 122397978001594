import cloneDeep from 'lodash/cloneDeep';
import { default as extend } from 'lodash/assignIn';

const initialState = {
	isSolved: false,
	isEdited: false
};

export function status(state = cloneDeep(initialState), action) {
	switch (action.type) {
		case 'input':
			return extend({}, state, {isEdited: true});
		default:
			return state;
	}
}
