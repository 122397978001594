import React, {useContext} from 'react';
import {GameContext} from '../context/GameContext';

const ReceiveInput = () => {

    const {handleInputChange} = useContext(GameContext);

    return (
        <div className='answers'>
            <form>
                <input maxLength='1' onChange={(e) => handleInputChange(e)} name="answerOne" type='text'
                       className='answers__input'  style={{backgroundColor: '#00a3a0'}} />
                <input maxLength='1' onChange={(e) => handleInputChange(e)} name="answerTwo" type='text'
                       className='answers__input' style={{backgroundColor: '#4e2379'}}/>
                <input maxLength='1' onChange={(e) => handleInputChange(e)} name="answerThree" type='text'
                       className='answers__input' style={{backgroundColor: '#0091da'}}/>
            </form>
        </div>
    )
}

export default ReceiveInput;